import React from 'react';
import doobie from "./doobie.png"
import pumpdotfun from "./pumpdotfun-icon.png"
import x from "./twitter-icon.png"
import tg from "./telegram-icon.png"
import "./App.css"

const Header = () => {
  return (
    <header>
      <h1>$doobie</h1>
    </header>
  );
};

const MainPart = () => {
  return (
    <main>
      <img src={doobie} alt="doobie" />
    </main>
  );
};

const Footer = () => {
  return (
    <footer>
      <div className="icons">
        <a href="https://pump.fun/" target="_blank" rel="noopener noreferrer">
          <img src={pumpdotfun} alt="doobie1" />
        </a>
        <a href="https://twitter.com/doobieonsol" target="_blank" rel="noopener noreferrer">
          <img src={x} alt="doobie2" />
        </a>
        <a href="https://t.me/+tW6AENIXLVFmMDUx" target="_blank" rel="noopener noreferrer">
          <img src={tg} alt="doobie3" />
        </a>
      </div>
    </footer>
  );
};

const App = () => {
  return (
    <div className="App">
      <Header />
      <MainPart />
      <Footer />
    </div>
  );
};

export default App;